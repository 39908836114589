<template>
  <div class="Aochen_peizhi">
    <!-- 设置 -->
    <van-popup
      v-model="batchShow"
      round
      closeable
      position="bottom"
      class="setting_tc"
    >
      <div>
        <div class="title">批量操作</div>
        <ul>
          <li>
            <img
              @click="querenchongqi"
              src="../../assets/img/homeAmend/restartIcon.png"
              alt=""
            />
            <p>重启</p>
          </li>
          <!-- <li>
            <img src="../../assets/icons/ic_shangchuan.png" alt="" />
            <p>上传文件</p>
          </li> -->
          <li @click="querenhuifuchuchan">
            <img src="../../assets/img/homeAmend/factoryResetIcon.png" alt="" />
            <p>恢复出厂</p>
          </li>
          <li @click="querenxiugaimingchen">
            <img src="../../assets/img/homeAmend/nameIcon.png" alt="" />
            <p>修改名称</p>
          </li>
          <li @click="querenyijianxinji">
            <img src="../../assets/img/homeAmend/newAircraft.png" alt="" />
            <p>一键新机</p>
          </li>
          <!-- <li @click="querengenghuanshebei">
            <img src="../../assets/img/homeAmend/changeIcon.png" alt="" />
            <p>更换设备</p>
          </li> -->
          <li @click="addClick">
            <img src="../../assets/img/homeAmend/addIcon.png" alt="" />
            <p>批量新增</p>
          </li>
          <li @click="xushi">
            <img
              :class="AllnewShow ? '' : 'AllnewShowImg'"
              src="../../assets/img/homeAmend/continueIcon.png"
              alt=""
            />
            <p :class="AllnewShow ? '' : 'AllnewShowP'">批量续费</p>
          </li>
          <!-- <li>
            <img
              class="AllnewShowImg"
              src="../../assets/img/homeAmend/tiemon.png"
              alt=""
            />
            <p class="AllnewShowP">批量续费</p>
          </li> -->
          <li @click="minute">
            <img src="../../assets/img/homeAmend/minuteIcon.png" alt="" />
            <p>管理分组</p>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>
  
  <script>
import { Toast } from "vant";
import {
  commandreboot,
  commandreset,
  instanceupdate,
  commandnewPad,
  instancechange,
  instancelist,
} from "@/api/index.js";
export default {
  name: "AochenyunshoujiAochenPeizhi",
  props: {
    Aochen_phone: {},
    AllnewShow: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      batchShow: false,
      chongqi_show: false,
      huifuchuchan_show: false,
      xiugaimingchen_show: false,
      yijianxinji_show: false,
      genghuanshebei_show: false,
      instanceName: "",
      // AllnewShow: false,
    };
  },

  mounted() {
    // this.getInstancelist();
  },
  watch: {
    batchShow(newValue) {
      this.$emit("batchShow", newValue);
      // 在这里进行其他操作或触发事件等...
    },
    immediate: true, // 立即监听数据
  },
  methods: {
    // getInstancelist() {
    //   instancelist().then((res) => {
    //     let arr = [];
    //     if (res.code == 200) {
    //       for (let i = 0; i < res.data.length; i++) {
    //         if (res.data[i].customize != "2") {
    //           arr.push(res.data[i]);
    //         }
    //       }
    //       if (arr.length > 0) {
    //         this.AllnewShow = true;
    //       }
    //     }
    //   });
    // },
    popupshows() {
      this.batchShow = !this.batchShow;
    },
    // 续时页面
    xushi() {
      if (this.AllnewShow) {
        this.$router.push({ path: "/allGoOnMoney" });
      }
    },
    // 复制
    issucopy() {
      this.copy(this.Aochen_phone.instanceId);
    },

    // 复制方法
    copy(value) {
      var aux = document.createElement("input");
      aux.setAttribute("value", value);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      Toast("复制成功！");
    },
    // 确认重启
    querenchongqi() {
      this.batchShow = false;
      this.$router.push({ path: "/batchAll", query: { operate: "重启" } });
    },
    // 确认恢复出厂
    querenhuifuchuchan() {
      this.batchShow = false;
      this.$router.push({ path: "/batchAll", query: { operate: "恢复出厂" } });
    },
    // 确认修改名称
    querenxiugaimingchen() {
      this.batchShow = false;
      this.$router.push({ path: "/batchAll", query: { operate: "修改名称" } });
    },
    // 确认一键新机
    querenyijianxinji() {
      this.batchShow = false;
      this.$router.push({ path: "/batchAll", query: { operate: "一键新机" } });
    },
    // 确认更换设备
    querengenghuanshebei() {
      this.batchShow = false;
      this.genghuanshebei_show = true;
    },
    // 批量新增
    addClick() {
      this.batchShow = false;
      this.$router.push({ path: "/newcloudphone", query: { newNum: 10 } });
    },
    // 分组管理
    minute() {
      this.$router.push("/grouping");
    },
    // 取消
    cancel() {
      // console.log("cancelcancelcancel");
    },
    // 一键新机取消
    equipmentCancel() {
      this.yijianxinji_show = false;
    },
    // 重启云手机
    async chongqi() {
      let that = this;
      let data = {
        instanceCodes: [that.Aochen_phone.instanceCode],
      };
      await commandreboot(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在一分钟内重启，期间无法操作！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 恢复出厂
    async huifuchuchan() {
      let that = this;
      let data = {
        instanceCodes: [that.Aochen_phone.instanceCode],
      };
      await commandreset(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在三分钟内恢复出厂设置，期间无法操作！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 修改名称
    async xiugaimingchen() {
      let that = this;
      let data = {
        id: that.Aochen_phone.id,
        instanceName: that.instanceName,
      };
      await instanceupdate(data).then((res) => {
        if (res.code == 200) {
          Toast("设备名称修改成功！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 一键新机
    async yijianxinji() {
      this.yijianxinji_show = false;
      let that = this;
      let data = {
        ids: [that.Aochen_phone.id],
      };
      await commandnewPad(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在一分钟内重启，期间无法操作！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 一键新机
    async yijianxinji1(e) {
      this.yijianxinji_show = false;
      let that = this;
      let data = {
        ids: [that.Aochen_phone.id],
        flag: e,
      };
      await commandnewPad(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在一分钟内重启，期间无法操作！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 更换设备
    async genghuanshebei() {
      let that = this;
      let data = {
        id: that.Aochen_phone.id,
      };
      await instancechange(data).then((res) => {
        if (res.code == 200) {
          Toast("更换设备成功");
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.AllnewShowImg {
  opacity: 0.5; /* 设置透明度为50% */
}
.AllnewShowP {
  opacity: 0.5; /* 设置透明度为50% */
}
.setting_tc {
  min-height: 26%;
}
.title {
  margin-top: 15px;
  font-size: 17px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #333333;
}
ul {
  margin: 24px 0;
  padding: 0 24px;
  text-align: left;
  li {
    text-align: center;
    display: inline-block;
    width: 25%;
    font-size: 14px;
    margin-bottom: 20px;
    img {
      width: 50px;
      height: 50px;
    }
    p {
      font-size: 13px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
.shebeigbh {
  margin: 12px 24px;
  width: 152px;
  height: 26px;
  background: #f5f5f5;
  border-radius: 6px 6px 6px 6px;
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}
</style>