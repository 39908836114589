import { render, staticRenderFns } from "./VeSdk.vue?vue&type=template&id=5fe51930&scoped=true"
import script from "./VeSdk.vue?vue&type=script&lang=js"
export * from "./VeSdk.vue?vue&type=script&lang=js"
import style0 from "./VeSdk.vue?vue&type=style&index=0&id=5fe51930&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe51930",
  null
  
)

export default component.exports