// axios 封装
import axios from 'axios'
import Vue from 'vue'
import router from '../router'
// import route from '../router'
let params_xb = location.href.indexOf('?')
import GLOBAL from "../utils/global";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
let params
let params_json
if (params_xb != -1) {
  params = location.href.slice(params_xb + 1)
  params_json = JSON.parse('{"' + params.replace('=', '":"') + '"}');
}
const uuid = require('uuid')
sessionStorage.setItem("uuidx", uuid.v1());
const server = axios.create({
  // 请求基地址
  baseURL: process.env.VUE_APP_URL,
  timeout: 200000 // 控制时间 2000 = 2s，
  // 请求头的配置
  // headers
})


// 请求拦截器
// 例：提示开会员
server.interceptors.request.use(async (config) => {
  let data = JSON.parse(localStorage.getItem('data'))
  if (data != null) {
    sessionStorage.setItem("uuid", data.uuid);
  }
  // 请求头配置 config
  let token, channelCode
  if (data) {
    token = 'Bearer ' + data.token
  } else {
    token = ''
  }

  if (JSON.parse(sessionStorage.getItem('channelCode'))) {
    channelCode = JSON.parse(sessionStorage.getItem('channelCode')).channelCode
  } else {
    channelCode = ''
  }
  try {
    const fp = await FingerprintJS.load();
    let result = await fp.get();
    config.headers.appDeviceld = result.visitorId
  } catch (error) {
    console.error('获取访问者标识符失败', error);
  }
  config.headers.Authorization = token
  config.headers.appSource = 3;
  config.headers.channelCode = channelCode;

  return config
})
// 响应拦截白名单
const noResponse = ['/groupbooking', '/issuingplatform', '/download', '/activationcode', '/historyKey', '/keyParticulars', '/commissionsharing', '/publicity', '/correlWe', '/Activetemplate', '/activebuy', '/forgotpassword', '/', '/attemptPlay', '/sixOneEightFestival', '/wxMiddlePage', '/clientServe', "/channelPreloaded", "/cpsreg", '/moreData', '/newGroupBooking', '/joinGroup','/specialBuyWay', '/formGroup','/joinToGroup']
// 发卡下面的页面拦截
const hairpin = ['/activationcode', '/historyKey', '/keyParticulars']
// 只删除用户信息，不刷新面
const noGO = ['/commissionsharing']
// 活动模版下面的页面拦截
const templateBelow = ['/activebuy']
// 响应拦截器
server.interceptors.response.use((res) => {
  let status = noResponse.indexOf(window.location.pathname) == -1
  let hairpinStatus = hairpin.indexOf(window.location.pathname) != -1
  let noGOState = noGO.indexOf(window.location.pathname) != -1
  let templateState = templateBelow.indexOf(window.location.pathname) != -1
  if (res.data.code == 401 && status) {
    // setTimeout(() => {
    router.push("/login");
    // }, 1000);
    localStorage.removeItem('token');
    localStorage.removeItem('data');
    // router.go();
  } else {
    // 激活码页面身份消息过期跳转到发卡页面登录
    if (res.data.code == 401 && hairpinStatus) {
      localStorage.removeItem('token');
      router.push("/issuingplatform");
    }
  }
  if (res.data.code == 401 && noGOState) {
    localStorage.removeItem('token');
    localStorage.removeItem('data');
  }
  // 活动模版下面的页面身份消息过期跳转到发活动模版页面登录
  if (res.data.code == 401 && templateState) {
    localStorage.removeItem('token');
    localStorage.removeItem('data');
    router.push("/Activetemplate");
  }
  return res.data
})

// vue 全局加上 $http
// 在组件中使用 this.$http 就相当于 server 了
Vue.prototype.$http = server
export default server