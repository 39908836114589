import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const server = [
    {
        path: '/groupingresult',
        name: 'Groupingresult',
        component: () => import('../../views/Groupbooking/Groupingresult.vue')
    }, {
        path: '/groupbooking',
        name: 'Groupbooking',
        component: () => import('../../views/Groupbooking/Groupbooking.vue')
    },
    {
        path: '/grouphugebenefit',
        name: 'GroupHugebenefit',
        component: () => import('../../views/Groupbooking/GroupHugebenefit.vue')
    },
    {
        path: '/newGroupBooking',
        name: 'NewGroupBooking',
        component: () => import('../../views/newGroupBooking/index.vue')
    },
    {
        path: '/joinGroup',
        name: 'JoinGroup',
        component: () => import('../../views/newGroupBooking/joinGroup.vue')
    },
    {
        path: '/groupPayCallBack',
        name: 'GroupPayCallBack',
        component: () => import('../../views/newGroupBooking/groupPayCallBack.vue')
    },
    {
        path: '/formGroup',
        name: 'FormGroup',
        component: () => import('../../views/formGroup')
    },
    {
        path: '/joinToGroup',
        name: 'JoinToGroup',
        component: () => import('../../views/formGroup/joinToGroup.vue')
    },
    {
        path: '/groupPayResult',
        name: 'GroupPayResult',
        component: () => import('../../views/formGroup/groupPayResult.vue')
    },
]

export default server
