import server from "@/plugins/http.js";
// 
export function groupList(params) {
  return server({
    url: '/api/app/group/v2/instance/list',
    method: 'get',
    params: params,
  })
}
// 新增分组
export function groupAdd(data) {
    return server({
      url: '/api/app/group/add',
      method: 'post',
      data: data,
    })
  }
// 新增分组
export function groupUpdate(data) {
    return server({
      url: '/api/app/group/update',
      method: 'post',
      data: data,
    })
  }
// 删除分组
export function groupDelete(data) {
    return server({
      url: '/api/app/group/delete',
      method: 'post',
      data: data,
    })
  }
// 移动分组
export function groupInstance(data) {
    return server({
      url: '/api/app/group/move/instance',
      method: 'post',
      data: data,
    })
  }
// 批量重命名
export function rename(data) {
    return server({
      url: '/api/app/instance/rename',
      method: 'post',
      data: data,
    })
  }