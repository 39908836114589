import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const server = [
    // {
    //     path: '/commissionsharing',
    //     name: 'commissionsharing',
    //     component: () => import('../../views/Commission/CommissionSharing.vue')
    // },
]

export default server
